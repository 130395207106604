import(/* webpackMode: "eager" */ "/vercel/path0/app/opengraph-image.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/selfie.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Link/Link.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Header/Header.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Footer/Footer.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Menu/Menu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/SkipToContent/SkipToContent.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/themes.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/layout.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeScript","ThemeProvider"] */ "/vercel/path0/contexts/ThemeContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/next@15.3.0-canary.46_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-react-c_1473bfbcf879da4e9d8fe83c5966171e/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.0-canary.46_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-react-c_1473bfbcf879da4e9d8fe83c5966171e/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.0-canary.46_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-react-c_1473bfbcf879da4e9d8fe83c5966171e/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.0-canary.46_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-react-c_1473bfbcf879da4e9d8fe83c5966171e/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"Geist\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"system-ui\",\"sans-serif\"],\"preload\":true}],\"variableName\":\"GeistSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.0-canary.46_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-react-c_1473bfbcf879da4e9d8fe83c5966171e/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"Geist_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"ui-monospace\",\"SFMono-Regular\",\"'SF Mono'\",\"Menlo\",\"Consolas\",\"'Liberation Mono'\",\"monospace\"],\"adjustFontFallback\":false,\"preload\":true}],\"variableName\":\"GeistMono\"}");
